<template>
    <div>
        <b-overlay
            :show="isLoadingDataProcess"
            :opacity="0.5"
            rounded="sm"
            variant="white"
        > 
            <template #overlay>
                <div class="text-center">
                <ez-icon
                    animation="cylon"
                    icon="ezGolf-icon-clock"
                    size="30"
                />
                <p id="cancel-label" style="font-size: 18px;">Please wait...</p>
                <b-form-input disabled style="min-width:500px" v-model="check" id="range-1"  type="range" :min="min" :max="max"></b-form-input>
                <p>{{ check }}/{{ max }}</p>
                </div>
            </template>
            <b-card>
            <b-row>
                <b-col cols="11">
                    <b-row>
                        <b-col cols="2">
                            <b-form-group label="Từ ngày">
                                <date-range-picker
                                v-model="keyWord.StartDate"
                                ref="picker"
                                :single-date-picker="true"
                                :ranges="false"
                                opens="right"
                                :time-picker-increment="1"
                                :show-dropdowns="true"
                                :auto-apply="true"
                                :locale-data="{
                                    format: 'mm/dd/YYYY',
                                    daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                    firstDay: 0
                                }"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="2">
                            <b-form-group label="Đến ngày">
                                <date-range-picker
                                v-model="keyWord.EndDate"
                                ref="picker"
                                :single-date-picker="true"
                                :ranges="false"
                                opens="right"
                                :time-picker-increment="1"
                                :show-dropdowns="true"
                                :auto-apply="true"
                                :locale-data="{
                                    format: 'mm/dd/YYYY',
                                    daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                    firstDay: 0
                                }"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="2">
                            <b-form-group label="Trạng thái">
                                <v-select
                                    v-model="keyWord.ProcessStatus"
                                    label="label"
                                    :options="dataProcessStatus"
                                    :reduce="item => item.value"
                                    :clearable="true"
                                    placeholder="Trạng thái"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="1" class="m-auto">
                    <b-button
                        variant="primary"
                        type="submit"
                        @click="getListHistoryDataProcessing()"
                        >
                        {{ $t('golf_common_search') }}
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
        <b-card
            no-body
            border-variant="0"
        >
            <div>
                <div class="p-2">
                    <b-row align-v="center">
                    <b-col md="2">
                        <div class="d-select-perPage">
                        <b-form-group
                            :label="$t('golf_starter_result_on_page')"
                            label-for="h-first-name"
                            label-cols-md="7"
                        >
                            <v-select
                            v-model="perPage"
                            :options="optionPerPage"
                            :clearable="false"
                            />
                        </b-form-group>

                        </div>
                    </b-col>
                    
                    <b-col align="right">
                        <b-button
                        variant="outline-primary"
                        class="mr-1"
                        @click="HandleClickDataProcessingAll()"
                        >
                        Xử lý số liệu
                        </b-button>

                    </b-col>
                    </b-row>
                </div>
                <div>
                    <b-overlay
                        :show="isLoading"
                        :opacity="0.3"
                        rounded="sm"
                    >
                    
                    <DxDataGrid
                        :ref="dataGridRef"
                        :height="460"
                        class="table-starter"
                        :show-borders="false"
                        :data-source="items"
                        :show-column-lines="true"
                        :show-row-lines="true"
                        :allow-column-reordering="true"
                        :allow-column-resizing="true"
                        column-resizing-mode="widget"
                        :column-auto-width="true"
                    >
                        <DxColumn
                        v-for="(item, i) in configTable"
                        v-if="(item.field != 'action') ? item.isShow : true"
                        :key="i.field"
                        :caption="item.caption"
                        :data-field="item.field"
                        :data-type="item.type"
                        :cell-template="(item.cellTemplate) ? item.cellTemplate : false"
                        :alignment="item.alignment"
                        :width="item.width"
                        :allow-editing="item.allowEditing"
                        :edit-cell-template="item.editCellTemplate"
                        :editor-options="item.editorOptions"
                        >
                        
                        <DxColumn
                            v-for="(band, j) in item.bands"
                            v-if="band.isShow && item.bands.length > 0"
                            :key="band.field"
                            :caption="band.caption"
                            :data-field="band.field"
                            :data-type="band.type"
                            :group-cell-template="customText"
                            :cell-template="(band.cellTemplate) ? band.cellTemplate : false"
                            :alignment="band.alignment"
                            :width="item.width"
                            :allow-editing="item.allowEditing"
                            :edit-cell-template="item.editCellTemplate"
                        />
                        </DxColumn>
                        <template #action="{ data }">
                            <b-button   variant="primary" @click="HandleClickDataProcessing(data.data)">
                                Xử lý dữ liệu
                            </b-button>
                        </template>
                        <template #Start-Time="{ data }">
                            <div v-if="data.data.StartTime">
                                {{ convertUTC(data.data.StartTime,'DD/MM/YYYY HH:mm') }}
                            </div>
                        </template>
                        <template #End-Time="{ data }">
                            <div v-if="data.data.EndTime">
                                {{ convertUTC(data.data.EndTime,'DD/MM/YYYY HH:mm') }}
                            </div>
                        </template>
                        <template #Report-Date="{ data }">
                            <div v-if="data.data.ReportDate">
                                {{ convertUTC(data.data.ReportDate,'DD/MM/YYYY') }}
                            </div>
                        </template>
                        <template #Total-Time="{ data }">
                            <div>
                                {{ (new Date(data.data.EndTime) - new Date(data.data.StartTime))/1000 }}
                            </div>
                        </template>
                        <template #Process-Status="{ data }">
                            <div>
                                <span  v-if="data.data.ProcessStatus == 'COMPLETED'">
                                    Thành công
                                </span>
                                <span  v-if="data.data.ProcessStatus == 'NOT COMPLETED'">
                                    Thất bại
                                </span>
                            </div>
                        </template>
                        <DxSelection
                        :select-all-mode="allMode"
                        :show-check-boxes-mode="checkBoxesMode"
                        mode="multiple"
                        />

                            <!-- Tuỳ chỉnh hiển thị rate name -->
                        <!-- Bật cố định column -->
                        <DxColumnFixing :enabled="true" />

                        <!-- Cho phép group -->
                        <DxGroupPanel :visible="false" />

                        <!-- Phân trang -->
                        <DxPaging :page-size="perPage" />
                        <DxPager
                        :visible="true"
                        display-mode="full"
                        :show-info="true"
                        />
                    </DxDataGrid>
                    </b-overlay>
                </div>
            </div>
        </b-card>
        </b-overlay>
        
    </div>
</template>
<script>
import { reportSetting } from '@/api/Report-Setting'
import 'devextreme/dist/css/dx.light.css'
import dayjs from 'dayjs'
import {
  DxTexts,
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'
const dataGridRef = 'dataGrid'
export default {
    components: {
        DxTexts,
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxPaging,
        DxPager,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRangeRule,
        DxRequiredRule,
        DxValueFormat,
        DxColumnChooser,
        DxColumnFixing,
        DxSelection,
        DxExport
    },
    data() {
        return {
            dataGridRef,
            keyWord: {
                StartDate: this.getUTC('start', 'day'),
                EndDate: this.getUTC('start', 'day'),
                ProcessStatus: null
            },
            dataProcessStatus: [
                {
                    label:'Thành công',
                    value: 'COMPLETED'
                },
                {
                    label:'Thất bại',
                    value: 'NOT COMPLETED'
                }
            ],
            currentPage: 1,
            perPage: 50,
            optionPerPage: [10, 50, 100],
            allMode: 'allPages',
            checkBoxesMode: 'onClick',
            configTable: [
                {
                caption: 'Ngày',
                field: 'ReportDate',
                isShow: true,
                alignment: 'center',
                cellTemplate: 'Report-Date',
                width: '9%',
                },
                {
                caption: 'Thời gian bắt đầu',
                field: 'StartTime',
                cellTemplate: 'Start-Time',
                isShow: true,
                alignment: 'center',
                width: '12%',
                },
                {
                caption: 'Thời gian kết thúc',
                field: 'EndTime',
                cellTemplate: 'End-Time',
                isShow: true,
                alignment: 'center',
                width: '12%',
                },
                {
                caption: 'Thời gian xử lý(giây)',
                field: '',
                cellTemplate: 'Total-Time',
                isShow: true,
                alignment: 'center',
                width: '12%',
                },
                {
                caption: 'Trạng thái',
                field: 'ProcessStatus',
                isShow: true,
                cellTemplate: 'Process-Status',
                alignment: 'center',
                width: '10%',
                },
                {
                caption: 'Trạng thái Email',
                field: 'StarterGroupCode',
                isShow: true,
                alignment: 'center',
                width: '10%',
                },
                {
                caption: 'Người xử lý',
                field: 'CreateByName',
                isShow: true,
                alignment: 'center',
                width: '15%',
                },
                {
                caption: '',
                field: 'action',
                isShow: true,
                cellTemplate: 'action',
                width: '10%',
                alignment: 'center',
                }
            ],
            items: null,
            isLoading: false,
            selectedRowsData: [],
            min: null,
            max: null,
            check: null,
            isLoadingDataProcess: false
        }
    },
    created() {
        this.getListHistoryDataProcessing()
    },
    computed: {
        dataGrid() {
            return this.$refs[dataGridRef].instance
        }
    },
    methods: {
        async getListHistoryDataProcessing() {
            this.isLoading = true
            await reportSetting.api_RLPD01(this.keyWord).then(res => {
                this.items = res.Data.LogProcessData
                this.isLoading = false
            })
        },
        async HandleClickDataProcessing(data) {
            const processCode = this.generateUUID() 
            this.isLoading = true
            var date = this.dayjs(data.ReportDate).utc().tz(localStorage.getItem('timeZone'))
                        .add(-(localStorage.getItem('timeZoneOffset') * 2 / 60), 'hour')
                        .format('YYYY-MM-DDTHH:mm:ss')
            var body = {
                StartDate: date +'Z',
                RStartDate: date +'Z',
                EndDate: date +'Z',
                REndDate: date +'Z',
                ProcessDataCode: processCode,
                RevenueCenterId : []
            }
            await reportSetting.api_RRCC00(body).then(async res => {
                await reportSetting.api_RRCC00E({
                            Date: date
                })
                await reportSetting.api_RRCC00D(body)
                this.showResToast(res)
                this.getListHistoryDataProcessing()
                this.isLoading = false
            })
        },
        async HandleClickDataProcessingAll() {
            this.getSelectedData()
            if(this.selectedRowsData.length > 0) {
                
                this.min = 0
                this.max = this.selectedRowsData.length
                this.check = 0
                this.isLoadingDataProcess = true
                for (let i = 0; i < this.max; i++) {
                    const processCode = this.generateUUID() 
                    var date = this.dayjs(this.selectedRowsData[i].ReportDate).utc().tz(localStorage.getItem('timeZone'))
                        .add(-(localStorage.getItem('timeZoneOffset') * 2 / 60), 'hour')
                        .format('YYYY-MM-DDTHH:mm:ss')
                    var body = {
                        StartDate: date +'Z',
                        RStartDate: date +'Z',
                        EndDate: date +'Z',
                        REndDate: date +'Z',
                        ProcessDataCode: processCode,
                        RevenueCenterId : []
                    }
                    await reportSetting.api_RRCC00(body).then(async res => {
                        await reportSetting.api_RRCC00E({
                            Date: date
                        })
                        await reportSetting.api_RRCC00D(body)
                        this.check += 1
                        if(this.check === this.max) {
                            this.isLoadingDataProcess = false
                            this.showToast('success', this.$t('golf_common_success'))
                            this.getListHistoryDataProcessing()
                        }
                    })
                }
            } else {
                this.showToast('error', 'Vui lòng chọn ngày để xử lý dữ liệu')
            }
        },
        getSelectedData() {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData()
        },
        generateUUID() { // Public Domain/MIT
            var d = new Date().getTime();//Timestamp
            var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16;//random number between 0 and 16
                if(d > 0){//Use timestamp until depleted
                    r = (d + r)%16 | 0;
                    d = Math.floor(d/16);
                } else {//Use microseconds since page-load if supported
                    r = (d2 + r)%16 | 0;
                    d2 = Math.floor(d2/16);
                }
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        },
    }
}
</script>
<style lang="scss">
     @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/dx-grid.scss';
  .dx-checkbox-container {
        justify-content: center;
    }
</style>