import {
    ROOT,
    API_IRCE01,
    API_RRCE01,
    API_URCE01,
    API_RRCE02,
    API_DRCE01,
    API_RRCZ01,
    API_IRCZ01,
    API_URCZ01,
    API_URCP01,
    API_RRCC00,
    API_RLPD01,
    API_URCC01,
    API_URGC01,
    API_URCT01,
    API_URCTC1,
    API_URCSN1,
    API_RRCC00P,
    API_RCS03,
    API_RCT04,
    API_RRCC00D,
    API_URCD01,
    API_URCR01,
    API_RRCC00E,
    API_URCZ02,
    API_RRCS04,
    API_RRCE05
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'
function api_RCS03(data) {
    return fetch(ROOT + API_RCS03, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function api_RRCE05(data) {
    return fetch(ROOT + API_RRCE05, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function api_RRCS04(data) {
    return fetch(ROOT + API_RRCS04, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function api_URCZ02(data) {
    return fetch(ROOT + API_URCZ02, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}


function api_RRCC00E(data) {
    return fetch(ROOT + API_RRCC00E, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function api_URCD01(data) {
    return fetch(ROOT + API_URCD01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function api_URCR01(data) {
    return fetch(ROOT + API_URCR01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function api_RRCC00D(data) {
    return fetch(ROOT + API_RRCC00D, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}

function api_RCT04(data) {
    return fetch(ROOT + API_RCT04, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}


function api_URCSN1(data) {
    return fetch(ROOT + API_URCSN1, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function api_RRCC00P(data) {
    return fetch(ROOT + API_RRCC00P, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function api_IRCE01(data) {
    return fetch(ROOT + API_IRCE01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}

function api_RRCE01(data) {
    return fetch(ROOT + API_RRCE01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}

function api_URCE01(data) {
    return fetch(ROOT + API_URCE01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}

function api_RRCE02(data) {
    return fetch(ROOT + API_RRCE02, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}

function api_DRCE01(data) {
    return fetch(ROOT + API_DRCE01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}


function api_RRCZ01(data) {
    return fetch(ROOT + API_RRCZ01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}

function api_IRCZ01(data) {
    return fetch(ROOT + API_IRCZ01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}

function api_URCZ01(data) {
    return fetch(ROOT + API_URCZ01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}

function api_URCP01(data) {
    return fetch(ROOT + API_URCP01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}

function api_RRCC00(data) {
    return fetch(ROOT + API_RRCC00, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}

function api_RLPD01(data) {
    return fetch(ROOT + API_RLPD01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function api_URCT01(data) {
    return fetch(ROOT + API_URCT01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function api_URCC01(data) {
    return fetch(ROOT + API_URCC01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function api_URGC01(data) {
    return fetch(ROOT + API_URGC01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
function api_URCTC1(data) {
    return fetch(ROOT + API_URCTC1, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
export const reportSetting = {
    api_IRCE01,
    api_RRCE01,
    api_URCE01,
    api_RRCE02,
    api_DRCE01,
    api_RRCZ01,
    api_IRCZ01,
    api_URCZ01,
    api_URCP01,
    api_RRCC00,
    api_RLPD01,
    api_URCT01,
    api_URCC01,
    api_URGC01,
    api_URCTC1,
    api_URCSN1,
    api_RRCC00P,
    api_RCS03,
    api_RCT04,
    api_RRCC00D,
    api_URCD01,
    api_URCR01,
    api_RRCC00E,
    api_URCZ02,
    api_RRCS04,
    api_RRCE05
}